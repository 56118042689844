<template>
  <div class="list">
    <notifications></notifications>
    <navbar title="Batix Software GmbH"></navbar>
    <div class="container-fluid">
      <div class="row">
        <div class="col-2">
          <div class="container-fluid">
            <head-menu-filter
              :col="12"
              class="d-xl-flex"
              headline="Filter"
            ></head-menu-filter>

            <div class="row mb-2">
              <div
                class="
                  viewfilter
                  block
                  br-t-l-0 br-t-r-0
                  w-100-p
                  br-t-l-xl-0 br-t-r-xl-0
                  mt-minus-10
                "
              >
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <input
                        v-model="filters.size"
                        class="form-control input"
                        type="text"
                        placeholder=" "
                      />
                      <label>{{ "Anzahl Records" }}</label>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-xl-12">
                    <div class="form-group">
                      <date-picker
                        id="date-von"
                        date-format="dd.mm.yy"
                        :show-icon="true"
                        :initial="filters.date"
                        field="date"
                        @updatefull="setFilterDate"
                      />
                      <label>{{ "Datum" }}</label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-10">
          <div class="container-fluid">
            <head-menu
              :col="12"
              class="d-xl-flex"
              :headline="'Rapportliste'"
              :anzahl="anzahlDaten"
              :anzahlTotal="anzahlTotal"
              :anzahlAusFilter="anzahlDatenFilter"
            ></head-menu>

            <div class="row block belegliste belegliste-small">
              <div class="w-100-p">
                <div>
                  <b-table
                    ref="selectableTable"
                    small
                    tbody-tr-class="item"
                    :items="listData"
                    :fields="fields"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :sort-compare="sortDates"
                    sort-icon-left
                    selectable
                    select-mode="range"
                    @row-dblclicked="details"
                  >
                    <!--
                    <template slot="top-row" slot-scope="{ fields }">
                      <td v-for="field in fields" :key="field.key">
                        <b-input
                          v-if="
                            field.key != 'selected' && field.searchable != false
                          "
                          size="sm"
                          v-model="filters[field.key]"
                          :placeholder="field.label"
                        />
                      </td>
                    </template>

                    <template v-slot:head(selected)="header">
                      <b-form-checkbox
                        ref="headerCheckbox"
                        v-model="header.selected"
                        @input="selectAllRows(header)"
                      ></b-form-checkbox>
                    </template>
					

                    <template v-slot:cell(selected)="row">
                      <b-form-checkbox
                        v-model="row.item.selected"
                        @input="onRowSelected(row)"
                      ></b-form-checkbox>
                    </template>
					-->
                  </b-table>
                  <infinite-loading
                    :identifier="infiniteId"
                    @infinite="infiniteHandler"
                  >
                    <div slot="spinner">{{ "Lade weitere Daten" }}</div>
                    <div slot="no-more">{{ "Keine weiteren Daten gefunden" }}</div>
                    <div slot="no-results">
                      {{ "Keine weiteren Daten gefunden" }}
                    </div>
                  </infinite-loading>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <loading-overlay v-show="loading"></loading-overlay>

  </div>
</template>
  
  <script>
import HeadMenu from "@/components/HeadMenu";
import HeadMenuFilter from "@/components/HeadMenuFilter";
import Navbar from "@/components/Navbar";

import DatePicker from "@/components/Datepicker";

import Rapport from "@/Rapport";
import store from "@/store";
import page from "@/mixins/Page";
import Dashboard from "@/mixins/Dashboard";

import { apiErrorToAlert } from "@/utils/Errorhandler";




export default {
  name: "RapportListe",
  components: {
    Navbar,
    HeadMenu,
    HeadMenuFilter,
    DatePicker

  },
  metaInfo() {
    return {
      titleTemplate: () => {
        return "BX: " + "Rapport";
      },
    };
  },
  mixins: [page, Dashboard],
  props: {},
  store,
  data() {
    return {
      sortBy: "data",
      sortDesc: true,
      fields: [
        //{ key: "selected", label: "" },
        {
          key: "date",
          sortable: true,
          searchable: false,
          label: "Datum",
        },
        {
          key: "person",
          sortable: true,
          searchable: false,
          label: "Person",
        },
      ],
      periods: [],
      events: [],
      reports: [],
    };
  },
  computed: {
    queryParams: function () {
      var params = {
        page: this.page,
        size: this.anzahlProPage,
      };

      params.student = this.setQueryParam(this.filters.student?.id);
      params.date = this.setQueryParam(this.filters.date);
      params.event = this.setQueryParam(this.filters.event);
      params.degree = this.setQueryParam(this.filters.degree);

      if (this.filters.size && this.filters.size != "") {
        params.size = this.filters.size;
      }

      return params;
    },
  },
  watch: {},
  created() {
    this.initFilter("rapport-liste", "shiftreport/", true);
    this.loadData();
    this.holeAuswahlwerte();
  },
  mounted() {
    /*this.setHeaders("global", [
      //"",
      "number",
      "surname",
      "firstname",
    ]);*/
  },
  methods: {
    loadData(){
       Rapport.get("shiftreport/", {})
        .then((response) => {
          this.reports = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        }); 
    },
    holeAuswahlwerte() {
      //Shift
      Rapport.get("workshift/", {})
        .then((response) => {
          this.shifts = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Ort
      Rapport.get("city/", {})
        .then((response) => {
          this.periods = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },
    
    setFilterPerson(student) {
      this.$set(this.filters, "student", student);
    },
    refresh() {
      this.resetLoading(this);
    },
    details(rapport) {
      console.log(rapport)
      this.$router.push({
        name: "schichtrapport",
        params: { id: rapport.id, anzeige: 0 },
      });
    },
  },
};
</script>
  
<style>
</style>
  