<template>
  <div :id="id" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-header">
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h4 class="modal-title">
            <font-awesome-icon
              icon="fa-regular fa-warning"
              class="text-warning"
            />
            {{ title }}
          </h4>
        </div>
        <div class="modal-body">
          <p>{{ text }}</p>
        </div>
        <div class="modal-footer text-center">
          <!--<button type="button" class="btn btn-default" @click="hide">
            Abbrechen
          </button>-->
          <button type="button" class="btn btn-primary" @click="confirm">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "bootstrap/dist/js/bootstrap";

import { modal } from "@/mixins/Modal";

export default {
  name: "ConfirmModal",
  mixins: [modal],
  props: {
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    onConfirm: {
      type: Function,
      required: false,
      default: () => {
        this.$emit("confirm");
      },
    },
  },
  methods: {
    standardFunction() {
      this.$emit("confirm");
    },
    confirm() {
      this.$emit("confirm");
      $("#" + this.id).modal("hide");
    },
  },
};
</script>

<style>
</style>
