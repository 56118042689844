import Vue from "vue";
import Vuex from "vuex";


import "../extensions/ArrayExtension";
import filter from "./filter";


import {
	SET_APPKEYS,
	SET_PERMISSIONS,
	SET_MITARBEITER,
	SET_WINDOW_HEIGHT,
	SET_WINDOW_WIDTH,
	SET_BERECHTIGUNGEN,
	SET_GRUPPEN,
	SET_FILTERSTATE,
} from "./mutations.type";

Vue.use(Vuex);

export default new Vuex.Store({
	state: {
		appkeys: [],
		mitarbeiter: null,
		windowHeight: 0,
		windowWidth: 0,
		berechtigungen: [],
		gruppen: [],
		filterState: null,
	},
	mutations: {
		[SET_MITARBEITER](state, mitarbeiter) {
			state.mitarbeiter = mitarbeiter;
		},
		[SET_WINDOW_HEIGHT](state, height) {
			state.windowHeight = height;
		},
		[SET_WINDOW_WIDTH](state, width) {
			state.windowWidth = width;
		},
		[SET_PERMISSIONS](state, permissions) {
			state.permissions = permissions;
		},
		[SET_BERECHTIGUNGEN](state, val) {
			state.berechtigungen = val;
		},
		[SET_GRUPPEN](state, val) {
			state.gruppen = val;
		},
		[SET_APPKEYS](state, val) {
			state.appkeys = val;
		},
		[SET_FILTERSTATE](state, val) {
			state.filterState = val;
		  },
	},
	modules: {
		filter: filter,
	},
	getters: {
		appkeys: (state) => state.appkeys,
		mitarbeiter: (state) => state.mitarbeiter,
		name: (state) =>
			state.mitarbeiter
				? `${state.mitarbeiter.vorname} ${state.mitarbeiter.nachname}`
				: "",
		windowHeight: (state) => state.windowHeight,
		windowWidth: (state) => state.windowWidth,
		filterState: (state) => state.filterState,
	},
})

/*export default new Vuex.Store({
	state: {
		appkeys: [],
		mitarbeiter: null,
		windowHeight: 0,
		windowWidth: 0,
		berechtigungen: [],
		gruppen: [],
	},
	mutations: {
		[SET_MITARBEITER](state, mitarbeiter) {
			state.mitarbeiter = mitarbeiter;
		},
		[SET_WINDOW_HEIGHT](state, height) {
			state.windowHeight = height;
		},
		[SET_WINDOW_WIDTH](state, width) {
			state.windowWidth = width;
		},
		[SET_PERMISSIONS](state, permissions) {
			state.permissions = permissions;
		},
		[SET_BERECHTIGUNGEN](state, val) {
			state.berechtigungen = val;
		},
		[SET_GRUPPEN](state, val) {
			state.gruppen = val;
		},
		[SET_APPKEYS](state, val) {
			state.appkeys = val;
		},
	},
	modules: {},
	getters: {
		appkeys: (state) => state.appkeys,
		mitarbeiter: (state) => state.mitarbeiter,
		name: (state) =>
			state.mitarbeiter
				? `${state.mitarbeiter.vorname} ${state.mitarbeiter.nachname}`
				: "",
		windowHeight: (state) => state.windowHeight,
		windowWidth: (state) => state.windowWidth,
	},
});*/