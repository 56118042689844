<template>
  <div
    id="app"
    :class="{ 'margin-top-70': $route && $route.name === 'Startseite' }"
  >
  <notifications></notifications>
    <ValidationObserver v-slot="{ invalid }">
      <div class="col-12 col-md-11 col-xl-8 form block">
        <div class="navbar-button">
          <div class="clickable" @click="$router.go(-1)" style="padding-left: 65px;">
            <font-awesome-icon icon="fa-regular fa-angle-left" size="lg" />
            <span>Zurück</span>
          </div>
          <div>
            <img src="https://stl-linie-13.batix.ch/pic/186112C89A2.svg" style="height: 80px; float: right; margin-right: 70px; margin-bottom: 20px;"/> 
          </div>
        </div>
        <div class="col-xl-6 formcontent pt-1 pb-3" style="text-align: center">
          <div style="font-size: 1.5rem !important"><b>Schichtrapport</b></div>
        </div>
        <ValidationProvider rules="required" immediate v-slot="{ passed }">
          <div class="col-md-11 form-group formcontent">
            <input
              v-model="report.person"
              class="form-control"
              tabindex="10"
              placeholder=" "
            />
            <label v-bind:class="{ 'text-danger': !passed }"
              >Fahrer:in</label
            >
          </div>
        </ValidationProvider>

        <div class="col-md-11 form-group formcontent">
          <date-picker
            date-format="dd.mm.yy"
            :initial="report.date"
            placeholer=" "
            :show-icon="true"
            :tabindex="8"
            @update="(val) => (report.date = val)"
          />
          <label>Datum</label>
        </div>

        <ValidationProvider rules="required" immediate v-slot="{ passed }">
          <div class="col-md-11 form-group formcontent">
            <v-select
              v-model="report.city_start"
              label="designation"
              :options="cities"
              eager="true"
              :tabindex="5"
              placeholder="Ort"
            >
              <span slot="no-options">Keine Auswahlwerte gefunden</span>
            </v-select>
            <label v-bind:class="{ 'text-danger': !passed }">Ort der Busübergabe Schichtbeginn</label>
          </div>
                    <div class="col-md-11 form-group formcontent">
            <v-select
              v-model="report.city_end"
              label="designation"
              :options="cities"
              eager="true"
              :tabindex="5"
              placeholder="Ort"
            >
              <span slot="no-options">Keine Auswahlwerte gefunden</span>
            </v-select>
            <label v-bind:class="{ 'text-danger': !passed }">Ort der Busübergabe Schichtende</label>
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" immediate v-slot="{ passed }">
          <div class="col-md-11 form-group formcontent">
            <v-select
              v-model="report.shift"
              label="designation"
              :options="shifts"
              eager="true"
              :tabindex="5"
              placeholder="Schicht"
            >
              <span slot="no-options">Keine Auswahlwerte gefunden</span>
            </v-select>
            <label v-bind:class="{ 'text-danger': !passed }">Schicht</label>
          </div>
        </ValidationProvider>
        <ValidationProvider
          rules="required|numeric"
          immediate
          v-slot="{ passed }"
        >
          <div class="col-md-11 form-group formcontent">
            <input
              v-model.number="report.passenger_count"
              class="form-control"
              tabindex="10"
              placeholder=" "
            />
            <label v-bind:class="{ 'text-danger': !passed }"
              >Durchschnittliche Anzahl beförderter Personen</label
            >
          </div>
          <div class="col-md-11 form-group formcontent">
            <input
              v-model.number="report.battery"
              class="form-control"
              tabindex="10"
              placeholder=" "
            />
            <label v-bind:class="{ 'text-danger': !passed }"
              >Batteriestand Ende der Schicht</label
            >
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" immediate v-slot="{ passed }">
          <div class="col-md-11 form-group formcontent">
            <v-select
              v-model="report.traffic"
              label="designation"
              :options="trafficstates"
              eager="true"
              :tabindex="5"
              placeholder="Verkehrsaufkommen"
            >
              <span slot="no-options">Keine Auswahlwerte gefunden</span>
            </v-select>
            <label v-bind:class="{ 'text-danger': !passed }"
              >Durchschnittliches Verkehrsaufkommen</label
            >
          </div>
        </ValidationProvider>
        <ValidationProvider
          rules="required|double"
          immediate
          v-slot="{ passed }"
        >
          <div class="col-md-11 form-group formcontent">
            <input
              v-model.number="report.mileage"
              class="form-control"
              tabindex="10"
              placeholder=" "
            />
            <label v-bind:class="{ 'text-danger': !passed }"
              >Kilometerstand Ende der Schicht</label
            >
          </div>
        </ValidationProvider>
        <ValidationProvider rules="required" immediate v-slot="{ passed }">
          <div class="col-md-11 form-group formcontent">
            <v-select
              v-model="report.weather"
              label="designation"
              :options="weatherstates"
              eager="true"
              :tabindex="5"
              placeholder="Wetter"
            >
              <span slot="no-options">Keine Auswahlwerte gefunden</span>
            </v-select>
            <label v-bind:class="{ 'text-danger': !passed }">Wetter</label>
          </div>
        </ValidationProvider>
        <div class="col-md-11 form-group formcontent">
          <textarea
            v-model="report.remarks"
            class="form-control"
            tabindex="10"
            placeholder=" "
          />
          <label>Bemerkungen / Spezielle Vorkommnisse</label>
        </div>
        <div class="col-md-11 form-group formcontent">
          <div class="form-group d-flex pt-2" style="flex-wrap: wrap">
            <label>Bild</label>
            <button
              v-if="!report.image"
              class="btn btn-success btn-sm"
              @click="oeffneBildModal"
              style="width: 60px; height: 30px; padding: 0 4px 0 0"
            >
              <font-awesome-icon icon="fa-regular fa-plus" class="mr-1" />
              <span>Bild</span>
            </button>

            <button
              v-if="report.image"
              class="btn btn-danger btn-sm"
              @click="fotoLoeschen"
              style="width: 30px; height: 30px; margin-left: auto"
            >
              <font-awesome-icon icon="fa-solid fa-ban" size="sm" />
            </button>

            <img
              v-if="report.image"
              class="profilbild mt-2"
              style="
                width: auto;
                height: auto;
                max-width: 100%;
                object-fit: contain;
                object-position: center;
              "
              :src="'https://opendoors-dev.batix.ch/files/' + report.image"
            />
          </div>

          <bild-modal
            :gid="id"
            id="bild-modal"
            @bildUploaded="initializeImage"
          ></bild-modal>
        </div>
        <div class="col-md-11 form-group formcontent">
          <v-select
            v-model="report.event_category"
            label="designation"
            :options="categories"
            eager="true"
            :tabindex="5"
            placeholder="Kategorie"
          >
            <span slot="no-options">Keine Auswahlwerte gefunden</span>
          </v-select>
          <label>Ereigniskategorie / <i>Bitte ausfüllen, wenn während der Schicht ein spezielles Ereignis eingetroffen ist.</i></label>
        </div>
        <div
          class="col-md-11 form-group formcontent"
          v-if="report.event_category"
        >
          <div class="row">
            <div class="col-md-6">Ereignisreport bereits erstellt</div>

            <div class="col-md-2 mr-0">
              <input
                type="radio"
                id="yes"
                value="yes"
                v-model="report.event_report_created"
              />
              <label class="ml-1" for="yes">Ja</label>
            </div>

            <div class="col-md-2 mr-0">
              <input
                type="radio"
                id="no"
                value="no"
                v-model="report.event_report_created"
              />
              <label class="ml-1" for="no">Nein</label>
            </div>
          </div>
        </div>

        <div class="col-md-11 form-group formcontent" align="center">
          <button
            class="btn btn-primary mr-3"
            @click="speichern"
            @shortkey="speichern"
            :disabled="invalid"
          >
            Absenden
          </button>
          <button
            v-shortkey.once="['ctrl', 's']"
            class="btn btn-primary"
            @click="zwischenSpeichern"
            @shortkey="speichern"
          >
            Zwischenspeichern
          </button>
        </div>
          <confirm-modal
            title="Aktion erfolgreich"
            text="Sie werden auf die Startseite weitergeleitet."
            id="confirm-modal"
            @confirm="redirectToHome"
          ></confirm-modal>
      </div>
    </ValidationObserver>
  </div>
</template>


<script>
import page from "@/mixins/Page";
import Navbar from "@/components/Navbar";

import store from "@/store";
import Rapport from "@/Rapport";
import BildModal from "@/components/Modals/BildModal";
import ConfirmModal from "@/components/Modals/ConfirmModal";
import { dropzoneOptionsBild } from "@/config/Dropzone";



import DatePicker from "@/components/Datepicker";
import { apiErrorToAlert } from "@/utils/Errorhandler";

import Mitarbeiter from "@/models/Mitarbeiter";

export default {
  name: "Schichtrapport",
  components: {
    DatePicker,
    BildModal,
    ConfirmModal
  },
  mixins: [page],
  store,
  props: {
    id: {
      type: String,
      default: null,
    },
    anzeige: {
      type: Number,
      default: 0,
    },

  },
  metaInfo() {
    return {
      titleTemplate: () => {},
    };
  },
  data() {
    return {
      name: "",
      report: {
        image: null,
        },
      cities: [],
      shifts: [],
      trafficstates: [],
      weatherstates: [],
      categories: [],

      allowSave: null
    };
  },
  computed: {},
  created() {},
  mounted() {
    this.allowSave = this.mutable;
    console.log(this.id);
    this.holeAuswahlwerte();

    if (this.id) {
      Rapport.get("shiftreport/", { params: { id: this.id } }).then(
        (response) => {
          console.log(response);
          this.initializeReport(response.data);
        }
      );
    }else{
               this.report.person =
        this.angemeldeterMitarbeiter.vorname +
        " " +
        this.angemeldeterMitarbeiter.nachname;

        const options = {
  year: "numeric",
  month: "2-digit",
  day: "2-digit",
};

      var date = new Date().toLocaleDateString('de-DE', options);
    this.report.date = date;
    }



  },
  methods: {
    initializeImage(imgResponse) {
      if(!imgResponse){
        this.report.image = null
      }else{
        let imgData = JSON.parse(imgResponse);
        console.log(imgData);
        this.report.image = imgData.id;
      }
    },
    deleteImg(){
        this.report.image = null
      },

    initializeReport(report) {
      this.report = report;
    },

    oeffneBildModal() {
      $("#bild-modal").modal("show");
    },

    oeffneConfirmModal() {
      $("#confirm-modal").modal("show");
    },

    redirectToHome() {
      this.$router.replace({name: "Startseite"});
    },

    fotoLoeschen() {
      Rapport.delete("docs/" + this.report.image).then(
        () => {
          this.$emit("bildUpdated");
          this.deleteImg();
        }
      );
    },

    speichern() {
      this.report.creator = this.angemeldeterMitarbeiter.id;
      this.report.draft = false;
      var json = this.report;

      if (!this.id) {
        Rapport.post("shiftreport/", json)
          .then((response) => {
            this.initializeReport(response.data);

            if (this.report.event_report_created == "no") {
              this.$router.replace({
                name: "ereignisrapport",
                params: {
                  id: null,
                  anzeige: 0,
                  shiftID: this.id,
                  driver: this.report.person,
                  date: this.report.date,
                  event_category: this.report.event_category,
                },
              });
            }else{
              this.oeffneConfirmModal();
            }
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
             this.$notify({
            type: "success",
            title: "Aktion erfolgreich.",
            text: "Report wurde gespeichert.",
          });
          });
      } else {
        Rapport.put("shiftreport/" + this.id, json)
          .then((response) => {
            this.initializeReport(response.data);

            if (this.report.event_report_created == "no") {
              this.$router.replace({
                name: "ereignisrapport",
                params: {
                  id: null,
                  anzeige: 0,
                  shiftID: this.id,
                  driver: this.report.person,
                  date: this.report.date,
                  event_category: this.report.event_category,
                },
              });
            }
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
             this.$notify({
            type: "success",
            title: "Aktion erfolgreich.",
            text: "Report wurde gespeichert.",
          });
          });
      }
    },

    zwischenSpeichern() {
      this.report.creator = this.angemeldeterMitarbeiter.id;
      this.report.draft = true;
      var json = this.report;

      if (!this.id) {
        Rapport.post("shiftreport/", json)
          .then((response) => {
            this.initializeReport(response.data);
        
           this.oeffneConfirmModal();
            
          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
             this.$notify({
            type: "success",
            title: "Aktion erfolgreich.",
            text: "Report wurde gespeichert.",
          });
          });
      } else {
        Rapport.put("shiftreport/" + this.id, json)
          .then((response) => {
            this.initializeReport(response.data);

          })
          .catch((e) => {
            this.$notify(apiErrorToAlert(e));
          })
          .finally(() => {
             this.$notify({
            type: "success",
            title: "Aktion erfolgreich.",
            text: "Report wurde gespeichert.",
          });
          });
      }
    },

    holeAuswahlwerte() {
      //Shift
      Rapport.get("workshift/", {})
        .then((response) => {
          this.shifts = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Ort
      Rapport.get("city/", {})
        .then((response) => {
          this.cities = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Verkehr
      Rapport.get("traffic/", {})
        .then((response) => {
          this.trafficstates = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //Wetter
      Rapport.get("weather/", {})
        .then((response) => {
          this.weatherstates = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });

      //EreignisKategorie
      Rapport.get("category/", {})
        .then((response) => {
          this.categories = response.data.content;
        })
        .catch((e) => {
          this.$notify(apiErrorToAlert(e));
        });
    },
  },
};
</script>

<style lang="scss">
.form {
  margin: auto;
  width: 50%;
  padding: 10px;
  justify-content: center;
}
.formcontent {
  margin: auto;
}
</style>
