import Rapport from "@/pages/Rapport/Rapport";
import Ereignisrapport from "@/pages/Rapport/Ereignisrapport";
import RapportListe from "@/pages/Dashboardlisten/RapportListe";
import EventRapportListe from "@/pages/Dashboardlisten/EventRapportListe";

export default [
	{
		path: "/Schichtrapport/:id?/",
		name: "schichtrapport",
		component: Rapport,
		props: route => {
			const a = parseInt(route.params.anzeige, 10);
			return {
			  id: route.params.id,
			};
		  }
	},
	{
		path: "/Ereignisrapport/:id?/",
		name: "ereignisrapport",
		component: Ereignisrapport,
		props: route => {
			const a = parseInt(route.params.anzeige, 10);
			return {
			  id: route.params.id,
			  shiftID : route.params.shiftID,
			  driver: route.params.driver,
			  date: route.params.date,
			  event_category: route.params.event_category
			};
		  }, 
	},
    {
		path: "/RapportListe",
		name: "rapport-liste",
		component: RapportListe
	},
	{
		path: "/EventRapportListe",
		name: "ereignisrapport-liste",
		component: EventRapportListe
	},

];