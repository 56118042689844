/* import Api from "@/Api"; */

export default {
	setup() {
		let appKeys = {
			/* EXAMPLE:
			  ROLLEN: {
				MITARBEITER: [],
			  },*/
		};
		/* EXAMPLE:
	Api.get("appkeys/").then((response) => {
	  for (var list in response.data) {
		if (!appKeys[list.toUpperCase()]) appKeys[list.toUpperCase()] = [];
 
		response.data[list].forEach((element) => {
		  //console.log(list + " - " + element.appkey);
 
		  appKeys[list.toUpperCase()][element.appkey.toUpperCase()] =
			element.id;
 
		  if (list == "rollen") {
			let uRolle = element.appkey.toUpperCase();
 
			if (uRolle.indexOf("EMPLOYEE") == 0)
			  appKeys.ROLLEN.MITARBEITER.push(element.id);
		  }
		});
	  }
	});
*/
		//  create global Constants
		this.app.config.gobalProperties.$const = (key) => {
			return appKeys[key];
		};
	}
};
