<template>
  <div class="kopf">
    <div class="container-fluid" :style="[{ 'background-color': '#124862' }]">
      <div class="left-buttons" style="z-index: 5">
        <div class="navbar-button">
          <router-link to="./">
            <font-awesome-icon icon="fa-duotone fa-home" />
            <span>Home</span>
          </router-link>
        </div>
        <div class="navbar-button">
          <div class="clickable" @click="$router.go(-1)">
            <font-awesome-icon icon="fa-regular fa-angle-left" size="lg" />
            <span>Zurück</span>
          </div>
        </div>
        <div class="navbar-button">
          <div class="clickable" @click="$router.go(1)">
            <font-awesome-icon icon="fa-regular fa-angle-right" size="lg" />
            <span>Vor</span>
          </div>
        </div>
        <div class="navbar-button">
          <a href="#" onclick="window.location.reload(true);">
            <font-awesome-icon icon="fa-regular fa-sync" />
            <span>Refresh</span>
          </a>
        </div>
        <div
          class="navbar-button ml-3"
          style="background-color: transparent; border: none; padding: 11px"
        >
          <img
            src="https://stl-linie-13.batix.ch/pic/186112BB059_200x200.svg"
          />
        </div>
      </div>

      <div
        class="HeaderTitle text-left ml-5 d-flex align-items-center"
        style="padding-left: 230px; padding-top: 0; height: 70px"
      >
        <span> Rapport </span>
        <div class="ml-auto" style="margin-right: 160px">
          <div style="font-size: 16px">
            <span> Betriebszentrale Bürozeiten: +41 52 624 53 67 </span>
          </div>
          <div style="font-size: 16px">
            <span> Betriebszentrale Piket: +41 76 343 10 46 </span>
          </div>
        </div>
      </div>

      <!-- <div
        class="HeaderTitle text-left ml-5"
        style="padding-left: 230px; bottom: 10px; left: 1000px"
      >
        <div style="font-size: 16px">
          <span> Betriebszentrale Bürozeiten: +41 52 624 25 00 </span>
        </div>
        <div style="font-size: 16px">
          <span> Betriebszentrale Piket: +41 76 343 10 46 </span>
        </div>
      </div> -->

      <div class="right-buttons">
        <div v-if="isAdmin" class="navbar-button" style="width: 85px">
          <router-link :to="{ name: 'rapport-liste' }">
            <font-awesome-icon icon="fa-duotone fa-box" />
            <span>Schichtrapports</span>
          </router-link>
        </div>

        <div v-if="isAdmin" class="navbar-button" style="width: 85px">
          <router-link :to="{ name: 'ereignisrapport-liste' }">
            <font-awesome-icon icon="fa-duotone fa-boxes-stacked" />
            <span>Ereignisrapports</span>
          </router-link>
        </div>

        <div class="usersign" style="padding-left: 10px; top: 11px"></div>

        <div class="navbar-button">
          <div class="clickable" @click="logout()">
            <font-awesome-icon icon="fa-duotone fa-door-open" />
            <span>Logout</span>
          </div>
        </div>

        <div class="usersign" style="padding-left: 25px; top: 11px">
          <div class="dropdown show">
            <a
              class="none-decoration"
              role="button"
              id="dropdownMenuLink"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <font-awesome-icon
                aria-hidden="true"
                icon="fa-duotone fa-user"
                style="margin-bottom: 0.35rem"
                size="2x"
              />
              <span>{{ name }}</span>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import page from "@/mixins/Page";
import store from "@/store";
import axios from "axios";
import server from "../server";

export default {
  name: "Navbar",
  components: {},
  props: {
    icon: { type: String, default: null },
  },
  mixins: [page],
  store,
  data() {
    return {
      isAdmin: false,
    };
  },
  mounted: function () {
    this.isAdmin =
      this.gruppen.findIndex((gruppe) => gruppe.id == "1774DD13671") == -1
        ? false
        : true;
  },
  computed: {
    name() {
      return this.$store ? this.$store.getters.name : "";
    },
    logoutUrl() {
      if (process.env.NODE_ENV === "development") {
        return `${server.url}logout/`;
      } else {
        return "/logout/";
      }
    },
  },
  methods: {
    async logout() {
      try {
        await axios.post(this.logoutUrl, {});
        window.location.href = "/";
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>
	
	<style lang="scss">
.bm-item-list {
  margin-left: 5%;
}
</style>
	