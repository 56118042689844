<template>
  <div
    id="app"
    :class="{ 'margin-top-70': $route && $route.name === 'Startseite' }"
  >
    <router-view v-if="angemeldeterMitarbeiter || loginError"></router-view>
  </div>
</template>

<script>
import store from "@/store";
import { SET_MITARBEITER, SET_GRUPPEN } from "@/store/mutations.type";

import Api from "@/Api";

export default {
  name: "App",
  components: {},
  data() {
    return {
      loginError: false,
    };
  },
  store,
  computed: {
    angemeldeterMitarbeiter: {
      get() {
        return this.$store.state.mitarbeiter;
      },
      set(value) {
        this.$store.commit(SET_MITARBEITER, value);
      },
    },
  },
  async created() {
    if (!this.angemeldeterMitarbeiter)
      try {
        let response = await Api.get("aktueller_mitarbeiter/");
        this.angemeldeterMitarbeiter = response.data.mitarbeiter;
        this.$store.commit(SET_GRUPPEN, response.data.gruppen);

        this.angemeldeterMitarbeiter.dashboard = response.data.dashboard;

        this.angemeldeterMitarbeiter.dashboard.json = JSON.parse(
          response.data.dashboard.json
        );
      } catch (e) {
        this.loginError = true;
      }
  },
  methods: {},
};
</script>

<style lang="scss">
@import "./scss/app.scss";

.margin-top-70 {
  margin-top: 70px !important;
}

.cursor-pointer {
  cursor: pointer;
}
</style>
