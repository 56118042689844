<template>
  <div class="row">
    <div
      :class="[colClass]"
      class="block-dark block-kopf-dark"
      :style="[
        isABBTS_Test()
          ? { 'background-color': '#248c8c' } // ABBTS-Testsystem Farbpalette
          : { 'background-color': '#124862' }, // reguläre Farbpalette
      ]"
    >
      <span class="primary-headline-text text-white">{{ headline }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "HeadMenuFilter",
  props: {
    headline: {
      type: String,
      required: true,
    },
    col: {
      type: Number,
      required: false,
      default: 2,
    },
  },
  computed: {
    colClass: function () {
      if (this.col) {
        return "col-xl-" + this.col;
      } else {
        return "col-xl-2";
      }
    },
  },
  methods: {
    isABBTS_Test() {
      if (
        process.env.VUE_APP_SERVER_URL === "https://abbts-test.bx-education.ch/"
      ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style>
</style>
